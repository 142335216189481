import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import Head from '../components/head'
import Layout from '../components/layout'

const NotFoundPage: FunctionComponent = () => {
  return (
    <Layout>
      <Head
        title='404 - Page Not Found'
      />

      <h1>Page Not Found</h1>

      <p>
        No page found at this location. Go to <Link to='/'>home page</Link>.
      </p>
    </Layout>
  )
}

export default NotFoundPage
